import { Component, Input, OnInit } from '@angular/core';
import { QepFileBrowserService } from '@app/modules/documents/services/qep-file-browser.service';
import { IToasterData } from '@common/models/IToasterData';
import { IAttachment } from '@common/models/IAttachment';
import { ConfirmationService } from 'primeng/api';
import { ApprovalRequestType } from '@common/enums/ApprovalRequestType';
import { IFundFamily } from '@common/models/IFundFamily';
import { IPortfolioCompany } from '@common/models/IPortfolioCompany';
import { forkJoin, Observable } from 'rxjs';
import { FundFamilyService } from '@app/modules/companies/services/fund-family.service';
import { IStorageStat } from '@common/models/IStorageStat';
import { FileBrowserRawData, QEPFileUploadEvent } from 'qep-file-browser/lib/qep-file-browser.interface';

@Component({
  selector: 'app-document-repository',
  templateUrl: './document-repository.component.html',
  styleUrls: ['./document-repository.component.scss'],
  providers: [ConfirmationService]
})
export class DocumentRepositoryComponent implements OnInit {

  @Input() fundsRequired = true;
  @Input() rawFiles: any[];
  @Input() showCompany = false;
  toasterData: IToasterData = {} as any;
  funds: IFundFamily[] = [];
  portfolioCompanies: IPortfolioCompany[] = [];
  responseReceived = false;
  breadcrumbVisible = false;
  versionHistory: undefined | {
    value: IStorageStat[];
    attachment: any;
    event: FileBrowserRawData
  };
  private readonly mimeTypes = {
    'pdf': 'application/pdf',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'txt': 'text/plain',
    'csv': 'text/csv',
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg'
  };

  constructor(
    private qepFileBrowserService: QepFileBrowserService,
    private confirmationService: ConfirmationService,
    private fundFamilyService: FundFamilyService
  ) { }

  ngOnInit(): void {
    this.sortFilesByQuarter();
    const apiCalls: Observable<any>[] = [
      this.qepFileBrowserService.getAllPorfolioCompanies()
    ];
    if (this.fundsRequired) {
      apiCalls.push(this.fundFamilyService.getAll())
    }
    forkJoin(apiCalls)
      .subscribe(([res1, res2]) => {
        this.portfolioCompanies = res1;
        this.funds = res2 || [];
        this.responseReceived = true;
      })
  }

  deleteFile(ev: any) {
    this.confirmationService.confirm({
      message: 'This will delete the selected file. Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const date = ev.investmentActivity === ApprovalRequestType.Valuation ? null : ev.name.split('_')[2];
        this.qepFileBrowserService.deleteFile(ev.mnemonic, ev.activityName, date, ev.investmentActivity, ev.documentType, 0, ev.name).subscribe({
          next: d => {
            this.toasterData = {
              message: d.message,
              status: 'success'
            }
            this.rawFiles = this.rawFiles.filter(rf => !(rf.storageStat.name === ev.name && rf.storageStat.path === ev.path));
          },
          error: error => {
            this.toasterData = {
              message: error.message,
              status: 'error'
            }
          }
        })
      },
      reject: () => {
        console.log('Submit cancelled');
      },
    });
  }

  downloadFile(ev: FileBrowserRawData) {
    this.qepFileBrowserService.downloadFile(ev.name, ev.path).subscribe({
      next: data => {
        let file = new Blob([data], { type: 'application/json' });
        const fileName = ev.name;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(fileURL);
        setTimeout(() => {
          this.toasterData = {
            message: `File ${ev.name} downloaded successfully.`,
            status: "success"
          }
        }, 100);
      },
      error: err => {
        this.toasterData = {
          message: `File ${ev.name} could not be downloaded.`,
          status: "error"
        }
      }
    })
  }

  downloadFiles(ev: FileBrowserRawData) {
    this.qepFileBrowserService.downloadFiles(ev.downloadPath.substring(1), ev.mnemonic).subscribe({
      next: data => {
        let file = new Blob([data], { type: 'application/zip' });
        const fileName = "documents.zip";
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(fileURL);
        setTimeout(() => {
          this.toasterData = {
            message: `Files downloaded successfully.`,
            status: "success"
          }
        }, 100);
      },
      error: () => {
        this.toasterData = {
          message: `Files could not be downloaded.`,
          status: "error"
        }
      }
    })
  }

  uploadFile(ev: QEPFileUploadEvent) {
    if (ev.originalFile) {
      this.confirmationService.confirm({
        message: `This will replace existing document ${ev.originalFile}. Are you sure that you want to proceed?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.completeUpload(ev)
        },
        reject: () => {
          console.log('Submit cancelled');
        },
      });
    } else {
      this.completeUpload(ev);
    }
  }

  viewFile(ev: FileBrowserRawData) {
    const ext = ev.name.split('.').pop().toLowerCase();
    const attachment: IAttachment = {
      fileName: ev.name,
      path: ev.path,
      mimeType: this.mimeTypes[ext],
      index: 0,
      attachmentType: ev.documentType
    };

    this.qepFileBrowserService.previewFile(attachment).subscribe({
      next: value => {
        const file = new Blob([value], { type: attachment.mimeType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "quilt_preview", "width=800,height=1024,popup=yes,scrollbars=yes,resizable=yes");
      },
      error: (err) => {
        this.toasterData = {
          message: `File preview failed.`,
          status: "error"
        }
      }
    })
  }

  viewVersionHistory(ev: FileBrowserRawData) {
    const ext = ev.name.split('.').pop().toLowerCase();
    const attachment: IAttachment = {
      fileName: ev.name,
      path: ev.path,
      mimeType: this.mimeTypes[ext],
      index: 0,
      attachmentType: ev.documentType,
      lastModifiedBy: ev.lastModifiedBy
    };
    this.qepFileBrowserService.getVersionAttachmentList(ev.mnemonic, ev.investmentActivity, ev.name, ev.activityName, this.getDate(ev))
      .subscribe(d => {
        this.versionHistory = {
          value: d,
          event: ev,
          attachment
        };
      })
  }

  downloadVersionTemplate(event) {
    const attachment = event.attachment;
    const version = event.version;
    const { mnemonic, investmentActivity, activityName } = this.versionHistory.event;
    this.qepFileBrowserService.downloadAttachmentVersion(mnemonic, investmentActivity, activityName, attachment, version, this.getDate(this.versionHistory.event))
      .subscribe({
        next: (value) => {
          const file = new Blob([value], { type: attachment.mimeType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "attachment_verion_history", "width=800,height=1024,popup=yes,scrollbars=yes,resizable=yes");
        }, error: (err) => {
          console.log(err)
        }
      })
  }

  toggleBreadCrumb(isShowing: boolean) {
    this.breadcrumbVisible = isShowing;
  }

  private async completeUpload(ev: QEPFileUploadEvent) {
    this.qepFileBrowserService.uploadFile(ev).subscribe({
      next: (res) => {
        const { mnemonic, investmentActivity, activityName, documentType } = ev.rawData;
        const { fileName, path, creationDate, lastModifiedDate, createdBy, lastModifiedBy } = res
        const data = {
          storageStat: {
            name: fileName,
            path,
            size: 0,
            isDirectory: false,
            isFile: true,
            birthtime: creationDate,
            mtime: lastModifiedDate,
            createdBy,
            lastModifiedBy
          },
          properties: {
            mnemonic,
            investmentActivity,
            activityName,
            documentType
          }
        }
        const newRawFiles = JSON.parse(JSON.stringify(this.rawFiles));
        if (ev.originalFile) {
          const existingFileIndex = this.rawFiles.findIndex(rf => rf.storageStat.name === fileName);
          newRawFiles[existingFileIndex] = data;
        } else {
          newRawFiles.push(data);
        };
        this.rawFiles = newRawFiles;
        this.toasterData = {
          message: `File ${ev.files[0].name} uploaded successfully.`,
          status: "success"
        };
      },
      error: (error: any) => {
        this.toasterData = {
          message: error.message || "A problem occurred while uploading documents.",
          status: "error"
        };
      }
    })
  }

  private sortFilesByQuarter() {
    this.rawFiles?.sort((a, b) => {
      const [yearA, quarterA] = a.storageStat.path.split('/')[2].split('-Q').map(Number);  // Split and parse year and quarter for `a`
      const [yearB, quarterB] = b.storageStat.path.split('/')[2].split('-Q').map(Number);  // Split and parse year and quarter for `b`

      // Compare years first
      if (yearA !== yearB) {
        return yearB - yearA;  // Descending order for years
      }

      // If years are the same, compare quarters
      return quarterB - quarterA;  // Descending order for quarters
    });
  }

  private getDate(ev: any): string {
    let date = ''
    if (ev.investmentActivity === ApprovalRequestType.Board_Materials) {
      date = ev.name.split('.')[0].split('_').pop();
    }
    return date;
  }
}
