import { Component, OnInit,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';

import { UserRole } from '@common/enums/UserRole';
import { RoleRoutesService } from '@app/core/auth/services/role-routes.service';
import { AppRoute } from '@app/core/enums/app-route.enum';
import { truckFlatbed } from 'ngx-bootstrap-icons';
import { MenuItem } from './models/menu-item.model';
import { SpinnerService } from '@app/services/common/SpinnerService';
import { environment } from 'src/environments/environment';
import { CurrentUserStateService } from '@app/core/auth/services/current-user-state.service';

const menuItems = [
    { title: 'Portfolio', link: AppRoute.Portfolio, exact: false},
    { title: 'Companies', link: AppRoute.Companies, exact: false },
    { title: 'Treasury', link: AppRoute.Treasury, exact: false },
    { title: 'IR', link: AppRoute.IR, exact: false },
    { title: 'Admin', link: AppRoute.Admin, exact: false },
    { title: 'Funds', link: AppRoute.Funds, exact: false },
    { title: 'Documents', link: AppRoute.Documents, exact: false }
];

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit,AfterContentChecked {
    public menuItems: MenuItem[] = menuItems;

    constructor(
    private roleRoutesService: RoleRoutesService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public spinnerService: SpinnerService,
    private currentUserStateService: CurrentUserStateService
    ) {}

    public ngOnInit(): void {
        this.defineMenuItemsRoles();
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
      }

    public isMenuNeeded(): boolean {
        return true;

        return this.router.url !== `/${AppRoute.Unauthorized}`;
    }

    private defineMenuItemsRoles(): void {
        const currentUser = this.currentUserStateService.value;
        let userInRole = this.roleRoutesService.isUserInRole(currentUser?.roles, UserRole.Admin) ;
        this.menuItems.forEach((item) => {
            item.roles = this.getRouteRoles(item.link);
        });
    }

    private getRouteRoles(route: string): UserRole[] {
        const roles = [];
        const roleRoutes = this.roleRoutesService.getAll();

        for (const role in roleRoutes) {
            if (roleRoutes[role].includes(route)) {
                roles.push(role);
            }
        }

        return roles;
    }
}
