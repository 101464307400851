<p-table [value]="groupedForecastData"  styleClass="p-datatable-sm" rowGroupMode="subheader" groupRowsBy="group">
  <ng-template pTemplate="header" >
      <tr>
          <th>Model Outputs</th>
          <th class="text-right" *ngFor="let col of cols">{{ col }}</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-rowData>
      <tr pRowGroupHeader >
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
      <tr class="group_{{rowData['key'].group}}" [ngClass]="(rowData['key']?.displayNameStyles === 'bold' || (rowData.metric ==='realizedPCMoic')) ? 'border-top totals-background' :'' ">
          <td [ngClass]="rowData['key']?.displayNameStyles">{{rowData.metric}}</td>
          <td class="text-right" *ngFor="let year of cols" [style.color]="rowData[year]['displayColor']" [ngClass]="rowData['key']?.displayNameStyles">
              {{ (rowData[year]['value'] ==='0' || rowData[year]['value'] ==='0%'|| rowData[year]['value'] ==='0.0x' || rowData[year]['value'] ==='$0' || rowData[year]['value'] === null ) ? '-' :  rowData[year]['value'] }}</td>
      </tr>
  </ng-template>
</p-table>
