// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// to allow dynamic environments to be used, in containers.
// Gets a value from the window object, if it exists, returning
// the default value if it does not.
function envVal(name, defaultValue) {
    if (window) {
        if (window['env']) {
            return window['env'][name];
        }
    }

    return defaultValue;
}

export const environment = {
    // token replacement is done in the build process
    appVersion: 'v3.5.54',
    production: envVal('envName', 'dev') !== 'local',
    appInsights: {
        instrumentationKey: envVal('instrumentationKey', '7b76a57c-20f5-4697-9cf2-d626cf788a59')
    },
    debug: envVal('envName', 'dev') === 'local',
    baseUrl: envVal('baseURL', 'https://localhost:8080/api/'),
    envName: envVal('envName', 'dev'),
    companyImageLocation: `${envVal('baseURL', 'https://localhost:8080/api/')}logo`,

    auth: {
        authConfig: {
            clientId: envVal('clientID', '9dc8d9f5-6ffb-486d-bf98-ccf570945b99'),
            authority: 'https://login.microsoftonline.com/0efbfb74-f81b-49d9-b5c6-40b2d1945cb6',
            redirectUri: window.location.origin
        },
        appScopeSets: [
            {
                resource: 'https://graph.microsoft.com/v1.0/me',
                scopes: ['User.Profile']
            },
            {
                resource: envVal('baseURL', 'https://localhost:8080/api/'),
                scopes: envVal('scopes', 'API.ReadWrite').split(',')
            }
        ]
    },
    photoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
    authority: 'https://login.microsoftonline.com/0efbfb74-f81b-49d9-b5c6-40b2d1945cb6',
    userScope: 'https://graph.microsoft.com/User.Read',
    sitesScope: 'https://graph.microsoft.com/Sites.Read.All',
    isAdminDashActive: true,
    helpUrl: 'https://qed.q360.ai/bo/QUILT.html'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
