/**
 * Types of attachment documents that can be added
 * to a capital call or other activity.
 */
export enum AttachmentType {
    capitalCallRequest = 'CapitalCallRequest',
    capitalizationTable = 'CapitalizationTable',
    boardResolution = 'BoardResolution',
    officersCertificate = 'OfficersCertificate',
    letterOfCredit = 'LetterOfCredit',
    promissoryNote = 'PromissoryNote',
    distributionSchedule = 'DistributionSchedule',
    valuationWriteUp = 'ValuationWriteUp',
    other = 'Other',
    forecast = 'Forecast',
    balanceSheet = 'BalanceSheet',
    reservePages = 'ReservePages',
    landSupport = 'LandSupport',
    dcfModels = 'DCFModels',
    publicCompanyComps = 'PublicCompanyComps',
    oAndGHedges = 'O&GHedges',
    stockPrice = 'StockPrice',
    foreignExchangeRate = 'ForeignExchangeRate',
    mipaPSA = 'MIPAPSA',
    sharesCertificates = 'SharesCertificates',
    assetMap = 'AssetMap',
    engineeringReport = 'EngineeringReport',
    sweData = 'SWEData',
    capitalLookback = 'CapitalLookback',
    variance = 'Variance',
    wellPerformance = 'WellPerformance',
    YearEndValuationSupportingDocuments = 'YearEndValuationSupportingDocuments'

}