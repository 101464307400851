import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserProfileService } from '@app/modules/user-profile/services/user-profile.service';
import { CommentsService } from '@app/services/common/comments.service';
import { ApprovalRequestType } from '@common/enums/ApprovalRequestType';
import { InputSource } from '@common/enums/InputSource';
import { NoteType } from "@common/enums/NoteType";
import { IToasterData } from '@common/models/IToasterData';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-comments',
  providers: [ConfirmationService],
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnChanges {
  comments: any;
  @Input() name ;
  @Input() mnemonic;
  @Input() type ;
  @Input() isNotesVisible;
  isEdit: boolean = false;
  activityType : ApprovalRequestType;
  columns = [
    { name: 'User', field: 'user' },
    { name: 'Source', field: 'source' },
    { name: 'Reason', field: 'value' },
    { name: 'Type', field: 'noteType' },
    { name: 'Created Date', field: 'createdAt' }
  ];
  isNotesTypeVisible: boolean = false;
  notes: string = '';
  user;
  toasterData: IToasterData = {};
  selectedComment;
  constructor(private commentsService: CommentsService, private userService: UserProfileService, public confirmationService: ConfirmationService) {
   this.userService.getMe().subscribe((res)=>{
     this.user = res
    })
   }

  ngOnChanges(): void {
    if(this.isNotesVisible){
      this.getComments()
    }
    if(this.type === 'distribution'){
      this.activityType = ApprovalRequestType.Distribution
    }
    else if(this.type === 'contribution'){
      this.activityType = ApprovalRequestType.CapitalCall
    }
    else if(this.type === 'commitment'){
      this.activityType = ApprovalRequestType.Commitment
    }
    else if(this.type === 'valuation'){
      this.activityType = ApprovalRequestType.Valuation
    }
    this.getComments()
  }
  getComments(){
    if(this.mnemonic){
      this.commentsService.get(this.mnemonic,this.activityType, this.name).subscribe({
        next : (res) =>{
          if(res.length > 0){
            for(let i =0;i<res.length;i++){
              this.userService.getUserByEmail(res[i].user).subscribe({
                next : (user) =>{
                  res[i].name = user.name ? user.name:res[i].email;
                },
                error :(error) =>{}
              })
            }
         
          }
          this.comments = [...res];
        },
        error :(error) =>{}
      })
    }
   
  }
  addNote(){
    this.isEdit = false;
    this.notes = '';
    this.isNotesTypeVisible = true;
  }
  noteChange(event){
    this.notes = event.target.value
  }
  onSave(){
    const notes ={
      mnemonic: this.mnemonic,
      name: this.name,
      value: this.notes,
      source: InputSource.QUILT,
      activityType: this.activityType,
      user: this.user?.email,
      noteType: NoteType.Other,
    }
    if(this.isEdit){
      this.selectedComment.value = this.notes ;
      this.commentsService.save(this.selectedComment).subscribe((res)=>{
        this.isNotesTypeVisible = false;
        this.toasterData = {
          status: 'success',
          summary: 'success',
          message: 'Successfully added'
        }
        this.getComments()
      })
    }
    else{
      this.commentsService.add(notes).subscribe((res)=>{
        this.isNotesTypeVisible = false;
        this.toasterData = {
          status: 'success',
          summary: 'success',
          message: 'Successfully added'
        }
        this.getComments()
      })
    }
  }
  editComments(comment){
    this.notes = comment.value;
    this.isNotesTypeVisible = true;
    this.isEdit = true;
    this.selectedComment =comment
  }
  onDelete(comment){
    this.confirmationService.confirm({
      message: 'This will delete the note. Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.commentsService.delete(comment).subscribe({
          next :(res) =>{
            this.toasterData = {
              status: 'success',
              message: 'Note deleted successfully'
            }
            this.getComments()
          },
          error: (error) => {}
        })
      }
    })
    
  }

}
