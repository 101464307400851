<q-file-browser *ngIf="responseReceived"
    [class.bc-visible]="breadcrumbVisible"
    [showCompany]="showCompany"
    [fundsRequired]="fundsRequired"
    [rawFiles]="rawFiles"
    [funds]="funds"
    [portfolioCompanies]="portfolioCompanies"
    (deleteFile)="deleteFile($event)"
    (downloadFile)="downloadFile($event)"
    (downloadFiles)="downloadFiles($event)"
    (uploadFile)="uploadFile($event)"
    (viewFile)="viewFile($event)"
    (viewVersionHistory)="viewVersionHistory($event)"
    (toggleBreadCrumb)="toggleBreadCrumb($event)"
>
</q-file-browser>
<app-confirm-dialog></app-confirm-dialog>
<app-toaster [toasterData]="toasterData"></app-toaster>
<p-dialog [contentStyle]="{'overflow': 'visible'}" [(visible)]="versionHistory" [modal]="true" [style]="{width: '1024px'}" *ngIf="versionHistory">
    <app-version-history [historyData]="versionHistory.value"
        [attachment]="versionHistory.attachment"
        (downloadHistory)="downloadVersionTemplate($event)"
        (cancelled)="versionHistory = undefined;">
    </app-version-history>
</p-dialog>