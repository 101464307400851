import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ApprovalRequestType } from '@common/enums/ApprovalRequestType';
import { AttachmentType } from '@common/enums/capitalCall/AttachmentType';
import { IAttachment } from '@common/models/IAttachment';
import { IPortfolioCompany } from '@common/models/IPortfolioCompany';
import { IRepositoryItem } from '@common/models/IRepositoryItem';
import { IStorageStat } from '@common/models/IStorageStat';
import { QEPFileUploadEvent } from 'qep-file-browser/lib/qep-file-browser.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QepFileBrowserService {

  constructor(private apiService: ApiService) { }

  getFiles(): Observable<IRepositoryItem[]> {
    return this.apiService.get<IRepositoryItem[]>('CentralRepository/listAll')
  }

  getPcFiles(mnemonic: string): Observable<IRepositoryItem[]> {
    return this.apiService.get<IRepositoryItem[]>(`CentralRepository/allAttachments?code=${mnemonic}`);
  }

  getAllPorfolioCompanies(): Observable<IPortfolioCompany[]> {
    return this.apiService.get<IPortfolioCompany[]>('PortfolioCompany')
  }

  downloadFile(fileName: string, path: string): Observable<Buffer> {
    return this.apiService.post('CentralRepository/download', { fileName, path }, {
      responseType: 'blob' as 'json'
    })
  }

  downloadFiles(path: string, mnemonic: string): Observable<Buffer> {
    return this.apiService.post('CentralRepository/downloadAll', { path }, {
      responseType: 'blob' as 'json',
      params: { mnemonic }
    })
  }

  deleteFile(code: string, quarter: string, date: string, investmentActivity: string, documentType: string, index: number, filename: string): Observable<any> {
    let url;
    if (investmentActivity === ApprovalRequestType.Valuation) {
      url = `PortfolioCompany/${code}/Valuation/${quarter}/Attachment/${documentType}/${index}/${filename}`;
    } else if (investmentActivity === ApprovalRequestType.Review) {
      url = `PortfolioCompany/${code}/Review/${quarter}/Attachment/${documentType}/${index}/${filename}`;
    } else {
      url = `PortfolioCompany/${code}/BoardMeeting/${quarter}/${date}/attachment/${documentType}/${index}/${filename}`;
    }

    return this.apiService.delete(url)
  }

  previewFile(attachment: IAttachment): Observable<Buffer> {
    return this.apiService.post('CentralRepository/preview', attachment, {
      responseType: 'blob' as 'json'
    })
  }

  uploadFile(ev: QEPFileUploadEvent): Observable<IAttachment> {
    let { rawData, description, date, files } = ev;
    const { mnemonic, activityName, investmentActivity, documentType } = rawData;
    let formData = new FormData();
    formData.append("uploads[]", files[0], files[0].name);

    let url: string;
    let index = 0;

    if ((investmentActivity as any) === ApprovalRequestType.Valuation) {
      if (documentType === AttachmentType.valuationWriteUp) {
        description = description || rawData.writeUpFund;
        if (!description.includes('(')) {
          description = '(' + description;
        }
        if (!description.includes(')')) {
          description += ')';
        }
        const firstBracketStartIndex = description.indexOf('(');
        const firstBracketEndIndex = description.indexOf(')');
        if (firstBracketEndIndex < firstBracketStartIndex) {
          description += ')';
        }
      }
      url = `PortfolioCompany/${mnemonic}/Valuation/${activityName}/Attachment/${documentType}/${index}`;
    } else if ((investmentActivity as any) === ApprovalRequestType.Review) {
      url = `PortfolioCompany/${mnemonic}/Review/${activityName}/Attachment/${documentType}/${index}`;
    } else {
      url = `PortfolioCompany/${mnemonic}/BoardMeeting/${activityName}/${date}/attachment/${documentType}/${index}`;
    }

    return this.apiService.post(url, formData, {
      params: { description }
    })
  }

  getVersionAttachmentList(mnemonic: string, investmentActivity: string, fileName: string, quarter: string, date: string): Observable<IStorageStat[]> {
    let url: string;
    if (investmentActivity === ApprovalRequestType.Valuation) {
      url = `PortfolioCompany/${mnemonic}/Valuation/${quarter}/Attachment/${fileName}`;
    } else if (investmentActivity === ApprovalRequestType.Review) {
      url = `PortfolioCompany/${mnemonic}/Review/${quarter}/Attachment/${fileName}`;
    } else {
      url = `PortfolioCompany/${mnemonic}/BoardMeeting/${quarter}/${date}/Attachment/${fileName}`;
    }
    return this.apiService.get<IStorageStat[]>(url);
  }

  downloadAttachmentVersion(mnemonic: string, investmentActivity: string, quarter: string, attachment, version: string, date: string): any {
    let url: string;
    if (investmentActivity === ApprovalRequestType.Valuation) {
      url = `PortfolioCompany/${mnemonic}/Valuation/${quarter}/Attachment/${version}`;
    } else if (investmentActivity === ApprovalRequestType.Review) {
      url = `PortfolioCompany/${mnemonic}/Review/${quarter}/Attachment/${version}`;
    } else {
      url = `PortfolioCompany/${mnemonic}/BoardMeeting/${quarter}/${date}/Attachment/${version}`;
    }
    return this.apiService.post(url, attachment, {
      headers: new HttpHeaders().set('Accept', attachment.mimeType),
      responseType: 'blob' as 'json'
    })

  }
}
